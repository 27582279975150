import { graphql } from 'gatsby';
import React from 'react';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Buttons from '../src/components/Buttons/Buttons';
import { Freya } from '../src/components/Freya/Freya';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Loki from '../src/components/Loki/lokipane';
import { MagniLeft } from '../src/components/Magni/MagniLeft';
import { MagniRight } from '../src/components/Magni/MagniRight';
import Mimir from '../src/components/Mimir/Mimir';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Saga } from '../src/components/Saga/Saga';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import './ficha_atraccion.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query calendarioQuery($atraccion_name: String!, $locale: String!) {
    allRidesDetailsHeaderBlock(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allRidesDetailsSeoData(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allRidesDetailsImageHeaderBlock(
      filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          image
        }
      }
    }
    allRidesDetailsMagniBlock(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          left_link_copy
          left_link_path
          right_link_copy
          right_link_path
        }
      }
    }
    allRidesDetailsSagaHeader(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          image
          alt
          video
          name
          mundos
        }
      }
    }
    allRidesDetailsSagaContent(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          summary
          description
          textBox
          content_textbox
        }
      }
    }
    allRidesDetailsLokiImages(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    bigLokiPics: allRidesDetailsLokiImages(
      filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allRidesDetailsMobileImage(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allRidesDetailsMimirIcons(
      filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          text
          icon
        }
      }
    }
    allRidesDetailsFreya(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allFichaAtraccionesBreadCrumbBlock(
      filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allRidesDetailsSeoRichData(filter: { tag: { eq: $atraccion_name }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class ridesDetails extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allRidesDetailsHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allRidesDetailsHeaderBlock.edges[0].node.description,
      location:
        this.props.data.allRidesDetailsSagaHeader.edges[0].node.mundos === 'Sesamo Aventura'
          ? 'SésamoAventura'
          : this.props.data.allRidesDetailsSagaHeader.edges[0].node.mundos,
      image: {
        url: this.props.data.allRidesDetailsImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
        alt: this.props.data.allRidesDetailsImageHeaderBlock.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allRidesDetailsMobileImage.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const buttons = {
      cta:
        this.props.pageContext.options[0] === 'ferrari-land'
          ? tt('/entradas/ferrari-land', this.props.pageContext.locale)
          : tt('/entradas', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };

    const sagaData = {
      image: this.props.data.allRidesDetailsSagaHeader.edges[0].node.image,
      video: this.props.data.allRidesDetailsSagaHeader.edges[0].node.video,
      altImage: this.props.data.allRidesDetailsSagaHeader.edges[0].node.alt,
      fileType: this.props.data.allRidesDetailsSagaHeader.edges[0].node.name,
      title: this.props.data.allRidesDetailsSagaContent.edges[0].node.title,
      summary: this.props.data.allRidesDetailsSagaContent.edges[0].node.summary,
      description: this.props.data.allRidesDetailsSagaContent.edges[0].node.description,
      textBox: this.props.data.allRidesDetailsSagaContent.edges[0].node.textBox,
      content_textbox: this.props.data.allRidesDetailsSagaContent.edges[0].node.content_textbox,
      social: false,
      button1: {
        cta:
          this.props.pageContext.options[0] === 'ferrari-land'
            ? tt('/entradas/ferrari-land', this.props.pageContext.locale)
            : tt('/entradas', this.props.pageContext.locale),
        size: 'alone',
        color: 'orange',
        ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
      },
      button2: {
        cta: tt('/descubrir', this.props.pageContext.locale),
        size: 'small',
        color: 'white',
        ctaText: tt('DESCUBRIR', this.props.pageContext.locale),
      },
    };

    const PrepareforFreya = (src) => ({
      cards: src.edges
        .map((e) => ({
          image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
          imgsize: e.node.imgsize,
          title: e.node.title,
          buttons: e.node.buttons,
        }))
        .filter((e) => e.image !== undefined),
    });

    const PrepareforMimir = (src) => {
      const exclusiveFilter = src.edges.filter(
        (element) => element.node.icon !== 'ReducedMobility'
      );
      const getExclusive = src.edges.filter((element) => element.node.icon === 'ReducedMobility');
      if (getExclusive.length) {
        return {
          icons: exclusiveFilter.map((e) => ({
            icon: e.node.icon,
            text: e.node.text,
          })),
          exclusive: getExclusive.map((e) => ({
            icon: e.node.icon,
            text: e.node.text,
          })),
        };
      }
      return {
        icons: exclusiveFilter.map((e) => ({
          icon: e.node.icon,
          text: e.node.text,
        })),
      };
    };

    const PrepareforLoki = () => ({
      arrayImg: this.props.data.allRidesDetailsLokiImages.edges
        .map((e) => ({
          img: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.alt,
        }))
        .filter((e) => e.img !== undefined),
      bigArrayImg: this.props.data.bigLokiPics.edges
        .map((e) => ({
          img: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.alt,
        }))
        .filter((e) => e.img !== undefined),
    });

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allRidesDetailsSeoData.edges[0].node._0.title}
          description={this.props.data.allRidesDetailsSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allRidesDetailsSeoRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allRidesDetailsImageHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="rides-details-content">
              <Mimir data={PrepareforMimir(this.props.data.allRidesDetailsMimirIcons)} />
              <div className="top-rides-details">
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={this.props.pageContext.pageName}
                  options={this.props.pageContext.options}
                  title={this.props.data.allFichaAtraccionesBreadCrumbBlock.edges[0].node.name}
                />
                <Buttons
                  link={buttons.cta}
                  size={buttons.size}
                  color={buttons.color}
                  text={buttons.ctaText}
                />
              </div>
              <BlueArrow />
              <div className="rides-arrows-content">
                <MagniLeft data={this.props.data.allRidesDetailsMagniBlock.edges[0].node} />
                <MagniRight data={this.props.data.allRidesDetailsMagniBlock.edges[0].node} />
              </div>
              <Saga data={sagaData} />
              <div className="loki-content">
                {!this.props.data.allRidesDetailsLokiImages.length ? null : (
                  <H4 title={tt('GALERIA DE FOTOS', this.props.pageContext.locale)} />
                )}
                {this.props.data.allRidesDetailsLokiImages && <Loki data={PrepareforLoki()} />}
              </div>
              <div className="promotion-content">
                <H4 title={tt('EXPLORA OTRAS ATRACCIONES', this.props.pageContext.locale)} />
                <Freya data={PrepareforFreya(this.props.data.allRidesDetailsFreya)} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ridesDetails;
